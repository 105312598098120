.join-room {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;

	input {
		margin-bottom: 1rem;
		padding: 0.5rem;
		border-radius: 5px;
		border: 1px solid var(--background2);
		width: 200px;
	}

	button {
		padding: 0.75rem 1.5rem;
		border: none;
		border-radius: 5px;
		background-color: var(--primary);
		color: var(--text);
		cursor: pointer;
		transition: background-color 0.3s ease;

		&:disabled {
			background-color: var(--background2);
			cursor: not-allowed;
		}

		&:hover:not(:disabled) {
			background-color: color-mix(in srgb, var(--primary) 90%, black);
		}
	}

	.magic-button {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		background-color: var(--accent);
		color: var(--background);

		&:hover:not(:disabled) {
			background-color: color-mix(in srgb, var(--accent) 90%, black);
		}
	}

	.validation-message {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		color: green;
		margin-top: 0.5rem;
	}
}

.channel-page {
	display: flex;
	flex-direction: column;
	background-color: var(--background);
	color: var(--text);
	height: 75vh;
	width: 100%;
	position: relative;
	min-height: 0;
	overflow: hidden;
	box-sizing: border-box;
  }


.room-container {
	display: flex;
	flex: 1;
	min-height: 0;
	position: relative;
}

.room {
	flex: 1;
	display: flex;
	flex-direction: column;
	min-height: 0;
}


.user-list {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 8px;
	padding: 8px;
	overflow-y: auto;
	flex: 1;
	min-height: 0;

	&.single {
		grid-template-columns: 1fr;
		max-width: 1200px;
		margin: 0 auto;
	}

	&.two {
		grid-template-columns: repeat(2, 1fr);
	}

	&.many {
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	}
}

.user {
	aspect-ratio: 16/9;
	background: var(--background-inverter);
	border-radius: 12px;
	overflow: hidden;
	position: relative;
	box-shadow: var(--shadow);

	.user-name {
		position: absolute;
		bottom: 12px;
		left: 12px;
		background: rgba(0, 0, 0, 0.6);
		color: white;
		padding: 4px 12px;
		border-radius: 4px;
		font-size: 14px;
	}

	.user-controls {
		position: absolute;
		top: 12px;
		right: 12px;
		display: flex;
		gap: 8px;
	}
}

.control-bar-container {
	background: rgba(32, 33, 36, 0.95);
	backdrop-filter: blur(10px);
	margin-top: auto;
}


.control-bar {
	width: 100%;
	height: 72px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 24px;

	.controls-left,
	.controls-center,
	.controls-right {
		display: flex;
		align-items: center;
		gap: 12px;
	}

	.control-time {
		color: #fff;
		font-size: 14px;
		margin-right: 24px;
	}
}

.control-button {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: none;
	background: rgba(255, 255, 255, 0.1);
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: all 0.2s;
	position: relative;

	&:hover {
		background: rgba(255, 255, 255, 0.2);
	}

	&.active {
		background: var(--primary);
	}

	&.end-call {
		background: #ea4335;
		width: 64px;
		border-radius: 32px;

		&:hover {
			background: #da3425;
		}
	}

	.tooltip {
		position: absolute;
		bottom: 100%;
		left: 50%;
		transform: translateX(-50%);
		background: rgba(0, 0, 0, 0.8);
		color: white;
		padding: 4px 8px;
		border-radius: 4px;
		font-size: 12px;
		white-space: nowrap;
		margin-bottom: 8px;
		opacity: 0;
		transition: opacity 0.2s;
	}

	&:hover .tooltip {
		opacity: 1;
	}
}

.participants-panel {
	position: fixed;
	right: 0;
	top: 0;
	bottom: 72px;
	width: 320px;
	background: var(--background);
	box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
	transform: translateX(100%);
	transition: transform 0.3s ease;
	z-index: 900;

	&.open {
		transform: translateX(0);
	}

	.panel-header {
		padding: 16px;
		border-bottom: 1px solid var(--background2);
		display: flex;
		justify-content: space-between;
		align-items: center;

		.close-button {
			background: none;
			border: none;
			color: var(--text);
			cursor: pointer;
			padding: 8px;

			&:hover {
				background: var(--background2);
				border-radius: 50%;
			}
		}
	}

	.participants-list {
		overflow-y: auto;
		height: calc(100% - 60px);
	}

	.participant-item {
		display: flex;
		align-items: center;
		gap: 12px;
		padding: 12px 16px;
		border-bottom: 1px solid var(--background2);

		.participant-avatar {
			width: 36px;
			height: 36px;
			border-radius: 50%;
			background: var(--background2);
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: 500;
		}

		.participant-info {
			flex: 1;

			.participant-name {
				font-weight: 500;
			}

			.participant-status {
				font-size: 12px;
				color: var(--text-secondary);
			}
		}
	}
}

.colab-modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: var(--background);
	padding: 24px;
	border-radius: 12px;
	width: 90%;
	max-width: 500px;
	z-index: 1100;
	box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);

	.modal-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;

		h3 {
			margin: 0;
		}

		.close-button {
			background: none;
			border: none;
			color: var(--text);
			cursor: pointer;
			padding: 8px;

			&:hover {
				background: var(--background2);
				border-radius: 50%;
			}
		}
	}

	.modal-content {
		display: flex;
		flex-direction: column;
		gap: 16px;

		input {
			padding: 12px;
			border-radius: 8px;
			border: 1px solid var(--background2);
			background: var(--background);
			color: var(--text);
			font-size: 14px;

			&:focus {
				outline: none;
				border-color: var(--primary);
			}
		}
	}

	.modal-buttons {
		display: flex;
		justify-content: flex-end;
		gap: 12px;
		margin-top: 24px;

		button {
			padding: 8px 16px;
			border-radius: 8px;
			border: none;
			cursor: pointer;
			font-weight: 500;

			&.cancel {
				background: var(--background2);
				color: var(--text);

				&:hover {
					background: var(--background3);
				}
			}

			&.submit {
				background: var(--primary);
				color: white;

				&:hover {
					background: color-mix(in srgb, var(--primary) 90%, black);
				}
			}
		}
	}
}

.colab-embed {
	flex: 0 0 50%;
	/* Take up 50% of the remaining width */
	max-width: 50%;
	z-index: 800;
	background: white;
	border-left: 1px solid var(--background2);
	/* Add a divider */
	display: flex;
	flex-direction: column;
	height: 100%;

	.colab-header {
		height: 48px;
		background: var(--background);
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 16px;
		border-bottom: 1px solid var(--background2);

		.close-button {
			background: none;
			border: none;
			color: var(--text);
			cursor: pointer;
			padding: 8px;
			border-radius: 50%;

			&:hover {
				background: var(--background2);
			}
		}
	}

	.colab-container {
		height: calc(100% - 48px);
		background: var(--background);
		overflow-y: auto; // Add scroll
		color: var(--text); // Ensure text is visible
	}
}

.gist-embed {
	width: 100%;
	height: 100%;
	overflow: auto;
	padding: 20px;
	background: white;

	.gist {
		height: 100%;

		.gist-file {
			margin-bottom: 0;
		}

		.gist-data {
			height: 100%;
			max-height: none;
		}
	}
}

.notebook-container {
	max-width: 900px;
	margin: 0 auto;
	padding: 20px;
	background: var(--background);
	color: var(--text);
	height: 100%;
	overflow-y: auto;
}

.notebook-viewer {
	background: var(--background);
	color: var(--text);
	padding: 20px;
}

.markdown-cell {
	margin: 20px 0;
	color: var(--text);

	// Style markdown elements
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: var(--text);
		margin: 1em 0 0.5em 0;
	}

	p {
		margin: 0.5em 0;
		line-height: 1.5;
	}

	a {
		color: var(--primary);
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	code {
		background: var(--background2);
		padding: 0.2em 0.4em;
		border-radius: 3px;
		font-family: monospace;
	}

	pre {
		background: var(--background2);
		padding: 1em;
		border-radius: 6px;
		overflow-x: auto;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	ul,
	ol {
		margin: 0.5em 0;
		padding-left: 2em;
	}
}

.code-cell {
	background: var(--background2);
	padding: 16px;
	border-radius: 6px;
	margin: 20px 0;

	pre {
		margin: 0;

		code {
			color: var(--text);
			font-family: monospace;
			white-space: pre-wrap;
		}
	}
}

.cell-output {
	margin-top: 10px;
	padding: 10px;
	border-left: 3px solid var(--background3);
	background: var(--background);
	color: var(--text);

	pre {
		margin: 0;
		white-space: pre-wrap;
		font-family: monospace;
	}
}

.room-container {
	display: flex;
	flex: 1;
	overflow: hidden;
	position: relative; // Add this
}

.room {
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding-bottom: 72px; // Add padding to account for control bar height
}

.control-bar-container {
	position: absolute; // Change from fixed to absolute
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	background: rgba(32, 33, 36, 0.95);
	backdrop-filter: blur(10px);
	z-index: 999;
}

.control-bar {
	width: 100%;
	height: 72px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 24px;
	z-index: 1000;

	// Remove the position: relative and bottom: 0 properties
	.controls-left,
	.controls-center,
	.controls-right {
		display: flex;
		align-items: center;
		gap: 12px;
	}

	.control-time {
		color: #fff;
		font-size: 14px;
		margin-right: 24px;
	}
}